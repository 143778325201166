import { SiteConfig } from '../../../../types/'
import sendFormToGravity from './sendFormToGravity'
import sendFormToSalesForce from './sendFormToSalesForce'

export default (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    home: string,
    config: SiteConfig
): Promise<any> => {
    sendFormToSalesForce(firstName, lastName, email, phone, home, config)
    return sendFormToGravity(firstName, lastName, email, phone, config)
}
