import React from 'react'
import './style.css'
import { HouseShopItem, ViewMode } from '../../../../types/ContentBuilder'
import { Theme } from '../../../../types'
import RoomList from './RoomList'

interface Props {
    props: HouseShopItem
    viewMode: ViewMode
    theme: Theme
}

export default ({ props, theme, viewMode }: Props) => (
    <li className="col-xl-4 col-md-6">
        <div className="house-card">
            {viewMode === ViewMode.Floorplan ? (
                <div
                    className="image"
                    style={{ backgroundImage: `url(${props.floorplan_image})` }}
                />
            ) : viewMode === ViewMode.Image ? (
                <div
                    className="image"
                    style={{ backgroundImage: `url(${props.image})` }}
                />
            ) : null}

            <div className="text-container">
                <div className="about-container">
                    <h4 style={{ color: theme.color.primary }}>
                        {props.address}
                    </h4>
                    <RoomList props={props.rooms} theme={theme} />
                    <p style={{ color: theme.color.secondary }}>
                        {props.about}
                    </p>
                </div>
                <h5>{props.price}</h5>
            </div>
        </div>
    </li>
)
