import React, { useState } from 'react'
import Input from '../../Common/Input'
import { SiteConfig } from '../../../types/ContentBuilder'
import { Theme } from '../../../types'
import './style.css'
import { getFontSize } from '../../../utils'
import subscribeUserWithEmail from '../../../utils/subscribeUserWithEmail'

interface Props {
    props: {
        header: {
            text: string
            font: string
            color: string
        }
        cta: {
            placeholder: string
            button: {
                text: string
                address: string
            }
            consent: string
        }
    }
    theme: Theme
    config: SiteConfig
}

export default ({ props, theme, config }: Props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const onSubmit = (email: string) => {
        setLoading(true)
        subscribeUserWithEmail(email, config)
            .then(() => {
                window.location.href = '/thank-you'
            })
            .finally(() => setLoading(false))
    }
    return (
        <section
            className="full_size_call_to_action"
            style={{ backgroundColor: theme.color.primary }}
        >
            <div className="container">
                <div className="row">
                    <div
                        className="col-lg-3 header-text"
                        style={{
                            fontFamily: props.header.font,
                            color: props.header.color,
                            fontSize: getFontSize(props.header.font, 20, 40),
                        }}
                    >
                        {props.header.text}
                    </div>
                    <div className="col-lg-9">
                        <Input
                            props={props.cta}
                            id={'form-submit-subscribe'}
                            theme={theme}
                            onSubmit={onSubmit}
                            loading={loading}
                            style={{
                                input: {
                                    color: 'white',
                                },
                                button: {
                                    backgroundColor: theme.color.secondary,
                                    color: 'white',
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
