import React from 'react'
import './style.css'
import { Theme } from '../../../types/'

interface Props {
    props: {
        header_text: string
        main_text: string
        right_image: string
        right_image_text: string
        left_image: string
        left_image_text: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="two_image_with_top_header_text">
        <div className="row text-container">
            <h2 style={{ color: theme.color.primary }}>{props.header_text}</h2>
            <p style={{ color: theme.color.third }}>{props.main_text}</p>
        </div>
        <div className="row image-container">
            <div className="col-lg-6">
                <img src={props.left_image} alt={props.header_text} />
                <p style={{ color: theme.color.third }}>
                    {props.left_image_text}
                </p>
            </div>
            <div className="col-lg-6">
                <img src={props.right_image} alt={props.header_text} />
                <p style={{ color: theme.color.third }}>
                    {props.right_image_text}
                </p>
            </div>
        </div>
    </section>
)
