import React, { useState } from 'react'
import { HouseShopItem, ViewMode } from '../../../types/ContentBuilder'
import { Theme } from '../../../types'
import './style.css'
import Tabs from './Tabs'
import HouseCard from './HouseCard'

interface Props {
    props: {
        house_type: {
            house_type: string
            houses: HouseShopItem[]
        }[]
    }
    theme: Theme
}

export default ({ props, theme }: Props) => {
    const tabs: string[] = props.house_type.map(house => house.house_type)
    const viewMode: string[] = ['Floorplans', 'Pictures']
    const houses: HouseShopItem[][] = props.house_type.map(house_type =>
        house_type.houses.map(house => house)
    )
    const [activeTab, setActiveTab] = useState<number>(0)
    const [activeViewMode, setActiveViewMode] = useState<ViewMode>(
        ViewMode.Floorplan
    )
    const [opacity, setOpacity] = useState<number>(1)
    const changeActiveHouseType = (index: number) => {
        setOpacity(0)
        setTimeout(() => {
            setActiveTab(index)
            setOpacity(1)
        }, 600)
    }
    const changeActiveViewMode = (index: number) => {
        setOpacity(0)
        setTimeout(() => {
            setActiveViewMode(index)
            setOpacity(1)
        }, 600)
    }
    return (
        <section className="floorplan_shop">
            <div className="tabs-container">
                <div className="tabs-container-content container">
                    <ul>
                        {tabs.map((tab, index) => (
                            <Tabs
                                key={index}
                                props={{
                                    label: tab,
                                    activeTab,
                                    index,
                                }}
                                onClick={() => changeActiveHouseType(index)}
                                theme={theme}
                            />
                        ))}
                    </ul>
                    <ul>
                        {viewMode.map((tab, index) => (
                            <Tabs
                                key={index}
                                props={{
                                    label: tab,
                                    activeTab: activeViewMode,
                                    index,
                                }}
                                onClick={() => changeActiveViewMode(index)}
                                theme={theme}
                            />
                        ))}
                    </ul>
                </div>
            </div>
            <div className="house-list-container container" style={{ opacity }}>
                <ul className="row">
                    {houses[activeTab].map((house, index) => (
                        <HouseCard
                            key={index}
                            props={house}
                            viewMode={activeViewMode}
                            theme={theme}
                        />
                    ))}
                </ul>
            </div>
        </section>
    )
}
