import React from 'react'
import RoomList from '../../Common/RoomList'
import { FloorPlan } from '../../../types/ContentBuilder'
import { Theme } from '../../../types'
import { RoomType } from '../../../types/HomeDesigns'

interface Props {
    floorplan: FloorPlan
    theme: Theme
}

export default ({ floorplan, theme }: Props) => {
    const rooms = {
        bedroom: {
            type: RoomType.Bedroom,
            count: parseInt(floorplan.rooms.bedroom),
        },
        living_room: {
            type: RoomType.LivingRoom,
            count: parseInt(floorplan.rooms.living_room),
        },
        bathroom: {
            type: RoomType.Bathroom,
            count: parseInt(floorplan.rooms.bathroom),
        },
        garage: {
            type: RoomType.Garage,
            count: parseInt(floorplan.rooms.garage),
        },
    }
    return (
        <div className="floorplan-item">
            <img src={floorplan.image} alt={'floorplan'} />
            <div className="title-price-container">
                <h2 style={{ color: theme.color.primary }}>
                    {floorplan.address}
                </h2>
                <h6 style={{ color: theme.color.primary }}>
                    {floorplan.price} ({floorplan.location})
                </h6>
            </div>
            <RoomList rooms={rooms} options={theme} />
            <p style={{ color: theme.color.third }}>{floorplan.description}</p>
            <a href={floorplan.link_address}>{floorplan.link_text}</a>
        </div>
    )
}
