import React from 'react'
import './style.css'
import { Theme } from '../../../types/'

interface Props {
    props: {
        background_image: string
        main_image: string
        header_section_paragraph: string
        header_section_text: string
        main_section_header_text: string
        main_section_main_text: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="double_image_right_aligned_text container-fluid">
        <div
            className="row first-section"
            style={{ backgroundImage: `url(${props.background_image})` }}
        >
            <div className="col-lg-12">
                <div className="text-container">
                    <p style={{ color: theme.color.third }}>
                        {props.header_section_paragraph}
                    </p>
                    <h2 style={{ color: theme.color.primary }}>
                        {props.header_section_text}
                    </h2>
                </div>
            </div>
        </div>
        <div className="row second-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 main-image">
                        <img
                            src={props.main_image}
                            alt={props.main_section_header_text}
                        />
                    </div>
                    <div className="col-lg-6 main-text-container">
                        <div className="text-container">
                            <h4 style={{ color: theme.color.primary }}>
                                {props.main_section_header_text}
                            </h4>
                            <p style={{ color: 'grey' }}>
                                {props.main_section_main_text}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)
