import React from 'react'

interface Props {
    onClick: Function
    arrowDirection: string
    arrowImage: string
    show: boolean
}

export default ({ onClick, arrowDirection, arrowImage, show }: Props) =>
    show ? (
        <div className={`arrow ${arrowDirection}`} onClick={() => onClick()}>
            <img src={arrowImage} alt={'arrow button'} />
        </div>
    ) : (
        <></>
    )
