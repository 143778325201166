import React from 'react'
import Divider from '../../Common/Divider'
import { Theme } from '../../../types/'
import './style.css'

interface Props {
    props: {
        header_text: string
        header_image: string
        header_image_text: string
        header_image_button: {
            text: string
            address: string
        }
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="header_image_text_center container-fluid">
        <div className="row">
            <div className="col-lg-12">
                <h2 style={{ color: theme.color.primary }}>
                    {props.header_text}
                </h2>
                <Divider />
                <div
                    className="header-image-container"
                    style={{ backgroundImage: `url(${props.header_image})` }}
                >
                    <h3 style={{ color: theme.color.secondary }}>
                        {props.header_image_text}
                    </h3>
                    <a
                        style={{ backgroundColor: theme.color.secondary }}
                        href={props.header_image_button.address}
                    >
                        {props.header_image_button.text}
                    </a>
                </div>
            </div>
        </div>
    </section>
)
