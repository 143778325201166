import React from 'react'
import Button from '../Common/Button'
import { ThemeOptions } from '../../types'
import './style.css'

interface TakeMeBackButtonProps {
    options: ThemeOptions
}

export default (Props: TakeMeBackButtonProps) => {
    const { options } = Props
    return (
        <div className="take-me-back-button">
            <Button
                className=""
                label={'< Take me back'}
                onClick={() => window.history.back()}
                style={{ backgroundColor: options.theme.color.secondary }}
                onHoverStyle={{ backgroundColor: options.theme.color.primary }}
            />
        </div>
    )
}
