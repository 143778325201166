import React from 'react'
import './style.css'
import { ThemeOptions } from '../../types'

interface BlogHeaderProps {
    headerText: string
    backgroundImage: string
    options?: ThemeOptions
}

export default (Props: BlogHeaderProps) => {
    const { headerText, backgroundImage } = Props
    return (
        <section
            className="blog-header"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="text-container">
                <h2>{headerText}</h2>
            </div>
        </section>
    )
}
