import React from 'react'
import './style.css'
import { Theme } from '../../../types/'

interface Props {
    props: {
        images: string[]
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="horizontal_image_list_view container-fluid">
        <ul>
            {props.images.map((image, index) => (
                <li key={index} style={{ backgroundImage: `url(${image})` }} />
            ))}
        </ul>
    </section>
)
