import React, { useState } from 'react'
import { Theme } from '../../../../types/'
import InputField from './InputField'
import { CallToActionDTO } from '../types'
import HyperLink from '../../../Common/HyperLink'
import './style.css'
import { Link } from 'react-scroll'

interface Props {
    props: CallToActionDTO
    theme: Theme
}

export default ({ props, theme }: Props) => {
    const [onButtonHover, setOnButtonHover] = useState<boolean>(false)
    if (props.cta_button_type === 'button') {
        if (props.scroll_to_cta)
            return (
                <Link
                    to={'subscribe-form'}
                    offset={-250}
                    smooth={true}
                    duration={1000}
                    className="sign-up-button"
                    onMouseEnter={() => setOnButtonHover(true)}
                    onMouseLeave={() => setOnButtonHover(false)}
                    style={{
                        backgroundColor: onButtonHover
                            ? theme.color.primary
                            : theme.color.secondary,
                    }}
                >
                    {props.button.text}
                </Link>
            )
        return (
            <HyperLink
                label={props.button.text}
                href={props.button.address}
                newTab={props.open_new_tab ? '_blank' : ''}
                style={{ backgroundColor: theme.color.secondary }}
                onHoverStyle={{ backgroundColor: theme.color.primary }}
            />
        )
    } else if (props.cta_button_type === 'input')
        return <InputField props={props} theme={theme} />
    return <> </>
}
