import React from 'react'
import './style.css'
import { Theme } from '../../../types'

interface Props {
    props: {
        right_top_image: string
        left_bottom_image: string
        left_top_image: string
        right_bottom_left_image: string
        right_bottom_right_image: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="gallery_grid container">
        <div className="row">
            <div className="col-lg-4">
                <div
                    className="image top-left"
                    style={{ backgroundImage: `url(${props.left_top_image}` }}
                />
                <div
                    className="image bottom-left"
                    style={{
                        backgroundImage: `url(${props.left_bottom_image}`,
                    }}
                />
            </div>
            <div className="col-lg-8">
                <div
                    className="image top-right"
                    style={{
                        backgroundImage: `url(${props.right_top_image}`,
                    }}
                />
                <div className="row">
                    <div className="col-lg-6">
                        <div
                            className="image bottom-right-right"
                            style={{
                                backgroundImage: `url(${props.right_bottom_left_image}`,
                            }}
                        />
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="image bottom-left-right"
                            style={{
                                backgroundImage: `url(${props.right_bottom_right_image}`,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
)
