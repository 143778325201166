import React, { useState } from 'react'
import { Theme } from '../../../types/'
import { Modal } from 'reactstrap'
import './style.css'
import Button from '../../Common/Button'

interface Props {
    props: {
        image: string
        title: string
        paragraph: string
        modal_button_text: string
        modal_link: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    return (
        <section className="image_left_text_right_modal">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <img src={props.image} alt={props.title} />
                    </div>
                    <div className="col-lg-6 vertical-center-container">
                        <div className="text-container">
                            <h2 style={{ color: theme.color.primary }}>
                                {props.title}
                            </h2>
                            <p style={{ color: 'grey' }}>{props.paragraph}</p>
                            <Button
                                label={props.modal_button_text}
                                onClick={() => setShowModal(!showModal)}
                                style={{
                                    backgroundColor: theme.color.secondary,
                                }}
                                onHoverStyle={{
                                    backgroundColor: theme.color.primary,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="tour-modal">
                <Modal
                    isOpen={showModal}
                    toggle={() => setShowModal(!showModal)}
                    size={'xl'}
                >
                    <iframe
                        src={props.modal_link}
                        style={{ width: '100%', height: 700 }}
                    />
                </Modal>
            </div>
        </section>
    )
}
