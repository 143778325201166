import React from 'react'
import './style.css'
import { Theme } from '../../../types/'

interface Props {
    props: {
        background_image: string
        top_header_image: string
        header_image: string
        location_text: string
        paragraph: string
    }
    theme: Theme
}

const downArrow = require('../../../images/down_arrow.png')

export default ({ props, theme }: Props) => (
    <section
        className="header_image_with_text"
        style={{
            backgroundImage: `url(${props.background_image})`,
        }}
    >
        <div />
        <div className="header-container">
            <div
                className="image"
                style={{ backgroundImage: `url(${props.header_image})` }}
            />
            <h1>{props.location_text}</h1>
            <p style={{ color: theme.color.third }}>{props.paragraph}</p>
        </div>
        <div className="bottom-container">
            <a>
                <div>SCROLL</div>
                <img src={downArrow} alt={'down arrow'} />
            </a>
        </div>
    </section>
)
