import React from 'react'

interface Props {
    index: number
    inputField: string
    inputType: string
    required: boolean
    inputs: string[]
    setInputs: Function
}

export default ({
    index,
    inputField,
    inputs,
    required,
    inputType,
    setInputs,
}: Props) => {
    const validateFieldType = (input: any): boolean => {
        input = input.charCodeAt(input.length - 1)
        /*
         * Validates Phone Number
         * Allows numers and plus sign for country code
         */
        if (inputType === 'tel') {
            if ((input < 48 && input !== 43) || input > 57) return false
        }
        return true
    }
    return (
        <input
            key={index}
            style={{
                color: 'white',
                borderBottomColor: 'white',
            }}
            type={inputType}
            placeholder={inputField}
            required={required}
            onChange={event => {
                if (validateFieldType(event.target.value)) {
                    inputs[index] = event.target.value
                    setInputs([...inputs])
                }
            }}
            value={inputs[index]}
        />
    )
}
