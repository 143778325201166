import React from 'react'
import './style.css'
import { Theme } from '../../../types/'

interface Props {
    props: {
        image: string
        name: string
        title: string
        summary: string
        button: {
            text: string
            address: string
        }
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="profile_summary container">
        <div className="text-container">
            <div
                className="profile-picture"
                style={{ backgroundImage: `url(${props.image})` }}
            />
            <h4 style={{ color: theme.color.primary }}>{props.name}</h4>
            <h5>{props.title}</h5>
            <p>{props.summary}</p>
            <a
                href={props.button.address}
                style={{ color: theme.color.secondary }}
            >
                {props.button.text}
            </a>
        </div>
    </section>
)
