import React from 'react'
import './style.css'

interface Props {
    props: {
        image: string
    }
}

export default ({ props }: Props) => (
    <section className="image_container container">
        <div
            className="image"
            style={{ backgroundImage: `url(${props.image})` }}
        />
    </section>
)
