import React from 'react'
import './style.css'
import { Theme } from '../../../../types/'

interface Props {
    props: {
        label: string
        activeTab: number
        index: number
    }
    onClick: Function
    theme: Theme
}

export default ({ props, onClick, theme }: Props) => (
    <li
        className="tabs"
        onClick={() => onClick()}
        style={{
            color:
                props.activeTab === props.index ? theme.color.primary : 'grey',
            textDecoration:
                props.activeTab === props.index ? 'underline' : 'none',
        }}
    >
        {props.label}
    </li>
)
