import React, { useState } from 'react'
import './style.css'
import { Category } from '../../../types/ContentBuilder'
import { Theme } from '../../../types/'

interface Props {
    props: {
        header_text: string
        map: string
        categories: Category[]
    }
    theme: Theme
}

export default ({ props, theme }: Props) => {
    const [showCategory, setShowCategory] = useState<number>(0)
    return (
        <section className="amenities_map container_fluid">
            <h2
                style={{
                    color: theme.color.primary,
                }}
            >
                {props.header_text}
            </h2>
            <div className="map-image">
                <img src={props.map} alt={props.header_text} />
            </div>
            <ul className="category-list">
                {props.categories.map((category, index) => (
                    <li key={index} onClick={() => setShowCategory(index)}>
                        {category.title}
                    </li>
                ))}
            </ul>
            <ul className="category-list-item">
                {props.categories[showCategory].list.map((list, index) => (
                    <li key={index}>{list}</li>
                ))}
            </ul>
        </section>
    )
}
