import axios from 'axios'
import { SiteConfig } from '../../../../types/'

export default (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    home: string = ' ',
    config: SiteConfig
): Promise<any> => {
    const params = {
        first_name: firstName ? firstName : '',
        last_name: lastName ? lastName : '',
        email: email ? email : '',
        lead_source: 'Web - Product Enquiry',
        oid: '00D7F000006kANH',
        retURL: config.BASE_SITE_URL,
        '00N0w000000bm1p': 'Web - Product Enquiry',
        '00N7F00000RaWrR': 'Yes',
        '00N7F00000QKZzG': 'PDX',
        '00N7F00000QVaXR': phone ? phone : '',
        '00N7F00000PqHfg': 'Regional',
        '00N7F00000QLLxr': 'House',
        '00N7F00000PqPDg': 'First Home Buyer',
        '00N7F00000RoK52': home,
    }
    // const formData = JSON.stringify(params)
    const formEndpoint =
        'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'
    // return axios({
    //     url: formEndpoint,
    //     method: 'POST',
    //     data: formData,
    //     headers: {
    //         Accept: 'application/json',
    //         'Content-Type': 'application/json',
    //     },
    // })

    return axios
        .get(formEndpoint, { params })
        .then(function(response) {
            console.log(response)
        })
        .catch(function(error) {
            console.log(error)
        })
        .finally(function() {
            // always executed
        })
}
