import React from 'react'
import { Theme } from '../../../types/'
import './style.css'
import Divider from '../../Common/Divider'
import Button from '../../Common/Button'

interface Props {
    props: {
        header_text: string
        image: string
        title: string
        button: {
            text: string
            address: string
        }
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="content-container col-lg-6">
        <h4 style={{ color: theme.color.primary }}>{props.header_text}</h4>
        <Divider />
        <div
            className="text-container image"
            style={{ backgroundImage: `url(${props.image})` }}
        >
            <h5 style={{ color: theme.color.secondary }}>{props.title}</h5>
            <Button
                label={props.button.text}
                onClick={() => {}}
                style={{ backgroundColor: theme.color.secondary }}
            />
        </div>
    </section>
)
