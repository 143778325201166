import React from 'react'
import Divider from '../../Common/Divider'
import { Theme } from '../../../types/'
import CategoryItem from './CategoryItem'
import './style.css'

interface Props {
    props: {
        header_text: string
        categories: []
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="categories_list_four_boxes container-fluid">
        <div className="row">
            <div className="col-lg-12">
                <h2 style={{ color: theme.color.primary }}>
                    {props.header_text}
                </h2>
                <Divider />
            </div>
        </div>
        <div className="row">
            {props.categories.map((category, index) => (
                <CategoryItem key={index} props={{ category }} theme={theme} />
            ))}
        </div>
    </section>
)
