import React from 'react'
import { Theme } from '../../../types/'
import './style.css'

interface Props {
    props: {
        header: string
        text_content: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="text_container_with_header container">
        <h4 style={{ color: theme.color.primary }}>{props.header}</h4>
        <div dangerouslySetInnerHTML={{ __html: props.text_content }} />
    </section>
)
