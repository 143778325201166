import React, { useContext } from 'react'
import { ThemeContext } from '../context/theme'
import { SectionComponent } from '../types'
import { SiteConfig } from '../types/ContentBuilder'

interface SectionComponentsProps {
    sections: SectionComponent[] | false
    config?: SiteConfig
}

export default ({ sections, config }: SectionComponentsProps) => {
    const theme = useContext(ThemeContext)
    return sections ? (
        <>
            {sections.map(Section =>
                Section ? (
                    <Section.component
                        props={Section.props}
                        theme={theme}
                        config={config}
                    />
                ) : (
                    <></>
                )
            )}
        </>
    ) : (
        <></>
    )
}
