import React from 'react'
import { Link } from 'gatsby'
import './style.css'
import { Theme } from '../../../types/'

interface Props {
    props: {
        background_image: string
        top_header_image: string
        logo_image: string
        header: {
            text: string
            color: string
        }
        link: {
            text: string
            color: string
            address: string
        }
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section
        className="header_image_with_link"
        style={{
            backgroundImage: `url(${props.background_image})`,
        }}
    >
        <div />
        <div className="header-container">
            <div
                className="image"
                style={{ backgroundImage: `url(${props.logo_image})` }}
            />
            <h1 style={{ color: props.header.color }}>{props.header.text}</h1>
        </div>
        <div className="bottom-container">
            <Link to={props.link.address}>
                <span style={{ color: props.link.color }}>
                    {props.link.text}
                </span>
            </Link>
        </div>
        <></>
    </section>
)
