import React from 'react'
import { Theme } from '../../../types/'
import './style.css'

interface Props {
    props: {
        main_header_text: string
        left_image: string
        right_image: string
        left_header_text: string
        left_main_text: string
        right_header_text: string
        right_main_text: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="two_image_containers_with_text container">
        <div className="row">
            <div className="col-lg-6 left-container">
                <div
                    className="image"
                    style={{ backgroundImage: `url(${props.left_image})` }}
                />
                <h3 style={{ color: theme.color.third }}>
                    {props.left_header_text}
                </h3>
                <p style={{ color: theme.color.third }}>
                    {props.left_main_text}
                </p>
            </div>
            <div className="col-lg-6 right-container">
                <div
                    className="image"
                    style={{ backgroundImage: `url(${props.right_image}` }}
                />
                <h3 style={{ color: theme.color.third }}>
                    {props.right_header_text}
                </h3>
                <p style={{ color: theme.color.third }}>
                    {props.right_main_text}
                </p>
            </div>
        </div>
    </section>
)
