import React, { useState } from 'react'

interface Props {
    address: string
    backgroundImage: string
    onHoverBackgroundColor: string
}

export default ({
    address,
    backgroundImage,
    onHoverBackgroundColor,
}: Props) => {
    const [onHover, setOnHover] = useState<boolean>(false)
    return (
        <div
            className="col-lg-6 category-item"
            style={{ backgroundColor: onHover ? onHoverBackgroundColor : '' }}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
        >
            <a
                href={address}
                className="image"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    opacity: onHover ? 0.5 : 1,
                }}
            />
        </div>
    )
}
