import React from 'react'
import CheckboxComponent from './Checkbox'
import { Checkbox, SubHeader } from './types'
import './style.css'
import { Theme } from '../../../types'

interface Props {
    props: {
        background_image: string
        header_text: string
        header_text_color: string
        sub_header: SubHeader
        checkboxes: Checkbox[]
    }
    theme: Theme
}

export default ({ props }: Props) => (
    <section
        className="checkbox"
        style={{ backgroundImage: `url(${props.background_image})` }}
    >
        <div className="container checkbox-container">
            <div className="row">
                <div className="col-lg-12">
                    <h2 style={{ color: props.header_text_color }}>
                        {props.header_text}
                    </h2>
                    <h3
                        style={{
                            color: props.sub_header.color,
                            fontFamily: props.sub_header.font.value,
                        }}
                    >
                        {props.sub_header.text}
                    </h3>
                </div>
            </div>
            <div className="row">
                {props.checkboxes.map((checkbox, index) => (
                    <CheckboxComponent
                        key={index}
                        props={checkbox}
                        theme={{ text_color: props.header_text_color }}
                    />
                ))}
            </div>
        </div>
    </section>
)
