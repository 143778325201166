export interface TextDecoration {
    text: string
    textColor: string
}
export interface HyperLink {
    address: string
    text: string
}
export interface QAItem {
    question: string
    answer: string
}
export interface InputField {
    placeholder: string
    underlineColor: string
    required?: boolean
}
export interface Category {
    title: string
    list: string[]
}
export interface FloorPlan {
    image: string
    address: string
    location: string
    price: number
    rooms: Rooms
    description: string
    link_text: string
    link_address: string
}
export interface Room {
    amount: number
    room_type: {
        value: string
        label: string
    }
}
export enum RoomType {
    Bedroom,
    Livingroom,
    Bathroom,
    Garage,
}

export interface ApiConfig {
    url: string
}

export interface Theme {
    header_text_color: string
    primary_background_color: string
    banner_text_color: string
    primary_text_color: string
    secondary_text_color: string
    third_text_color: string
}

export interface Checkbox {
    image: string
    header_text: string
    paragraph: string
}

export interface SubHeader {
    text: string
    font: { value: string; label: string }
    color: string
}

export interface Metadata {
    title: string
    description: string
    lang: string
    author: string
}

export interface SiteConfig {
    CMS_ENDPOINT: string
    BASE_SITE_URL: string
    API_ENDPOINT: string
    ENVIRONMENT: string
    GRAVITY_CONSUMER_KEY: string
    GRAVITY_CONSUMER_SECRET: string
    RECAPTCHA: string
    MAILCHIMP_SUBSCRIBE_ENDPOINT: string
    MAILCHIMP_API_KEY: string
}

export interface Rooms {
    bedroom: string
    living_room: string
    bathroom: string
    garage: string
}

export interface House {
    address: string
    about: string
    storeys?: string
    image: string
    price: string
    link: HyperLink
    flyer: HyperLink
    rooms: Rooms
    location: {
        location: string
        suburb: string
    }
}
export interface HouseShopItem extends House {
    floorplan_image: string
}
export enum RoomEnum {
    Bedroom,
    Bathroom,
    Garage,
    LivingRoom,
}
export enum ViewMode {
    Floorplan,
    Image,
}

export interface NavigationItemDTO extends HyperLink {
    button_style: string
}
