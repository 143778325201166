import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { ThemeProvider } from '../context/theme'
import mapSectionsToComponent from '../utils/mapSectionsToComponent'
import SectionComponents from '../components/SectionComponents'
import SubscribeForm from '../components/SubscribeForm'
import { SiteConfig } from '../types/ContentBuilder'
import BlogHeader from '../components/BlogHeader'
import TakeMeBackButton from '../components/TakeMeBackButton'

const searchHeaderBackgroundImage = require('../images/search_background_image.png')

const themeDefault = {
    color: {
        primary: '#001689',
        secondary: '#1dbbb2',
        third: 'grey',
    },
}

interface HelpAdviceSingleProps {
    pageContext: {
        content: []
        title: string
        config: SiteConfig
    }
}

export default ({ pageContext }: HelpAdviceSingleProps) => {
    const sections = mapSectionsToComponent(pageContext.content)
    return (
        <ThemeProvider value={themeDefault}>
            <Layout>
                <SEO title="Help And Advice" />
                <BlogHeader
                    headerText={pageContext.title}
                    backgroundImage={searchHeaderBackgroundImage}
                />
                <SectionComponents
                    sections={sections}
                    config={pageContext.config}
                />
                <TakeMeBackButton options={{ theme: themeDefault }} />
                <SubscribeForm
                    theme={themeDefault}
                    config={pageContext.config}
                />
            </Layout>
        </ThemeProvider>
    )
}
