import React from 'react'
import './style.css'

interface Props {
    props: {
        title: string
        content: string
    }
}

export default ({ props }: Props) => (
    <section className="free_text_container">
        <div className="text-container">
            <h2>{props.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
    </section>
)
