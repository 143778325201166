import React from 'react'
import './style.css'
import { Theme } from '../../../types'

interface Props {
    props: {
        header: {
            text: string
            color: string
            font: string
        }
        sub_header: {
            text: string
            color: string
            font: string
        }
    }
    theme: Theme
}

export const HeaderCenterText = ({ props, theme }: Props) => (
    <section className="header_center_text container">
        <div className="row">
            <div className="col-lg-12">
                <h2
                    style={{
                        color: props.header.color,
                        fontFamily: props.header.font,
                    }}
                    className={props.header.font}
                >
                    {props.header.text}
                </h2>
                <h3
                    style={{
                        color: props.sub_header.color,
                        fontFamily: props.sub_header.font,
                    }}
                    className={props.sub_header.font}
                >
                    {props.sub_header.text}
                </h3>
            </div>
        </div>
    </section>
)

export default HeaderCenterText
