import React from 'react'
import CategoryItem from './CategoryItem'
import './style.css'
import { Theme } from '../../../types'
import Divider from '../../Common/Divider'

type Category = {
    image: string
    header: {
        text: string
        address: string
    }
}

interface Props {
    props: {
        header_text: string
        categories: Category[]
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="categories_list_boxes container">
        <div className="row">
            <h2 style={{ color: theme.color.primary }}>Categories</h2>
            <Divider />
        </div>
        <div className="row">
            {props.categories.map((category, index) => (
                <CategoryItem
                    key={index}
                    onHoverBackgroundColor={theme.color.primary}
                    address={category.header.address}
                    backgroundImage={category.image}
                />
            ))}
        </div>
    </section>
)
