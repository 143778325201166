import React, { useState } from 'react'
import InputFieldItem from './InputFieldItem'
import './style.css'
import { SiteConfig, Theme } from '../../../types/'
import submitForm from './submitForm'
import { navigate } from 'gatsby'
import Recaptcha from 'react-google-recaptcha'
import validateFormFields from './validateFormFields'

interface Props {
    props: {
        header_text: string
        background_image: string
        input_fields: { field: string; type: string; required: boolean }[]
        consent: string
    }
    theme: Theme
    config: SiteConfig
}

export default ({ props, theme, config }: Props) => {
    const [inputs, setInputs] = useState<string[]>(['', '', '', '', ''])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [recaptcha, setRecaptcha] = useState<boolean>(false)
    const [checkbox, setCheckbox] = useState<boolean>(false)
    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setError('')

        const firstName = inputs[0]
        const lastName = inputs[1]
        const email = inputs[2]
        const phone = inputs[3]
        const home = inputs[4]

        const validated: boolean = validateFormFields(email)

        if (!recaptcha || !checkbox || !validated) {
            setError('Please fill out the fields correctly')
            return
        }
        setLoading(true)
        submitForm(firstName, lastName, email, phone, home, config)
            .then(() => {
                setLoading(false)
                navigate('/thank-you')
            })
            .catch(error => {
                setLoading(false)
                setError('There was an error sending the form')
            })
    }
    return (
        <section
            id="enquire-form"
            className="contact_form form-dropdown"
            style={{
                backgroundImage: `url(${props.background_image})`,
            }}
        >
            <div id="contact-form" className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <form onSubmit={event => handleOnSubmit(event)}>
                            <h3>{props.header_text}</h3>
                            {props.input_fields
                                ? props.input_fields.map(
                                      (inputField, index) => (
                                          <InputFieldItem
                                              key={index}
                                              index={index}
                                              inputField={inputField.field}
                                              inputType={inputField.type}
                                              required={inputField.required}
                                              inputs={inputs}
                                              setInputs={setInputs}
                                          />
                                      )
                                  )
                                : null}
                            <div className="row repatcha">
                                <div className="col-md-6">
                                    <div className="recaptcha">
                                        <p>
                                            <input
                                                type="checkbox"
                                                checked={checkbox}
                                                onChange={() =>
                                                    setCheckbox(!checkbox)
                                                }
                                            />
                                            <span>{props.consent}</span>
                                        </p>
                                        <Recaptcha
                                            sitekey={config.RECAPTCHA}
                                            onChange={result =>
                                                result
                                                    ? setRecaptcha(true)
                                                    : setRecaptcha(false)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="submit"
                                        value={
                                            loading && !error
                                                ? 'Loading...'
                                                : 'REGISTER >'
                                        }
                                    />
                                    {error ? (
                                        <span className="submit-error">
                                            {error}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
