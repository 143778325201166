import React from 'react'
import { Theme } from '../../../types'
import CallToAction from './CallToAction'
import './style.css'
import { CallToActionDTO } from '../ImageLeftCtaRight/types'

interface Props {
    props: {
        header_text: string
        sub_title: string
        paragraph: string
        image: string
        cta: CallToActionDTO
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="image_right_cta_left container">
        <div className="row">
            <div className="col-lg-6 text-container">
                <h6 style={{ color: theme.color.primary }}>
                    {props.sub_title}
                </h6>
                <h3 style={{ color: theme.color.primary }}>
                    {props.header_text}
                </h3>
                <p>{props.paragraph}</p>
                <CallToAction props={props.cta} theme={theme} />
            </div>
            <div
                className="col-lg-6 image"
                style={{ backgroundImage: `url(${props.image})` }}
            />
        </div>
    </section>
)
