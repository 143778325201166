import React, { useContext, useState } from 'react'
import './style.css'
import Button from '../../Common/Button'
import { ThemeContext } from '../../../context/theme'
import { Link } from 'react-scroll'

interface Props {
    props: {
        image: string
        header_text: string
        button_text: string
        button_color: string
        button_image: string
        button_link?: string
        button_open_tab: boolean
    }
}

export default ({ props }: Props) => {
    const theme = useContext(ThemeContext)
    const [onHover, setOnHover] = useState<boolean>(false)
    return (
        <section className="call_to_action">
            <div className="text-container">
                <img src={props.image} alt={props.header_text} />
                <h2 style={{ color: theme.color.primary }}>
                    {props.header_text}
                </h2>
                {props.button_link && props.button_open_tab ? (
                    <div className="call-to-action-button">
                        <a
                            href={props.button_link}
                            target="_blank"
                            onMouseEnter={() => setOnHover(true)}
                            onMouseLeave={() => setOnHover(false)}
                            style={{
                                backgroundColor: onHover
                                    ? theme.color.primary
                                    : props.button_color,
                            }}
                        >
                            {props.button_text}
                        </a>
                    </div>
                ) : props.button_link && !props.button_open_tab ? (
                    <div className="call-to-action-button">
                        <a
                            href={props.button_link}
                            target="_blank"
                            onMouseEnter={() => setOnHover(true)}
                            onMouseLeave={() => setOnHover(false)}
                            style={{
                                backgroundColor: onHover
                                    ? theme.color.primary
                                    : props.button_color,
                            }}
                        >
                            {props.button_text}
                        </a>
                    </div>
                ) : (
                    <Link
                        to={'contact-form'}
                        offset={-250}
                        smooth={true}
                        duration={1000}
                    >
                        <div className="call-to-action-button">
                            <Button
                                label={props.button_text}
                                onClick={() => {}}
                                onHoverStyle={{
                                    backgroundColor: theme.color.primary,
                                }}
                                style={{ backgroundColor: props.button_color }}
                            />
                        </div>
                    </Link>
                )}
            </div>
        </section>
    )
}
