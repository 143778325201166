import React from 'react'
import { Theme } from '../../../../types/'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

interface Props {
    props: string
    state: {
        state: boolean
        setState: Function
    }
    theme: Theme
}

export default ({ props, state, theme }: Props) => {
    const showReadMore = props.length > 100
    if (!state.state && showReadMore)
        return (
            <p>
                {props.substr(0, 80)}...{' '}
                <span
                    style={{
                        color: theme.color.secondary,
                    }}
                    className="read-more"
                >
                    More...
                    <FontAwesomeIcon icon={faAngleDown} />
                </span>
            </p>
        )
    else if (state.state && showReadMore) {
        return (
            <p>
                {props}
                <span
                    style={{
                        color: theme.color.secondary,
                    }}
                    className="read-more"
                >
                    Less...
                    <FontAwesomeIcon icon={faAngleUp} />
                </span>
            </p>
        )
    } else return <p>{props}</p>
}
