import React from 'react'
import './style.css'
import { Theme } from '../../../types/'

interface Props {
    props: {
        text: string
        text_color: string
        paragraph: string
        paragraph_color: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="text-container-two-fields container">
        <div className="row">
            <div className="col-lg-12">
                <h2 style={{ color: theme.color.primary }}>{props.text}</h2>
                {props.paragraph ? (
                    <p
                        style={{
                            color: props.paragraph_color
                                ? props.paragraph_color
                                : theme.color.third,
                        }}
                    >
                        {props.paragraph}
                    </p>
                ) : null}
            </div>
        </div>
    </section>
)
