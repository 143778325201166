import React from 'react'
interface Props {
    props: { image: string; header_text: string; paragraph: string }
    theme: { text_color: string }
}

export default ({ props, theme }: Props) => (
    <div className="checkbox-item">
        <img src={props.image} width={70} height={70} alt={props.header_text} />
        <div className="text-container">
            <h4 style={{ color: theme.text_color }}>{props.header_text}</h4>
            <p style={{ color: theme.text_color }}>{props.paragraph}</p>
        </div>
    </div>
)
