import React from 'react'
import { Theme } from '../../../types'
import './style.css'
import ContentContainer from './ContentContainer'

interface Props {
    props: {
        left_container: {
            header_text: string
            image: string
            title: string
            button: {
                text: string
                address: string
            }
        }
        right_container: {
            header_text: string
            image: string
            title: string
            button: {
                text: string
                address: string
            }
        }
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="two_image_containers_with_link_and_image container">
        <div className="row">
            <ContentContainer props={props.left_container} theme={theme} />
            <ContentContainer props={props.right_container} theme={theme} />
        </div>
    </section>
)
