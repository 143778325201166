import React from 'react'
import { Theme } from '../../../types/'
import './style.css'

interface Props {
    props: {
        image: string
        header_text: string
        main_text: string
        link_address: string
        link_text: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="circle_image_with_text">
        <div className="container">
            <div className="row">
                <div className="image">
                    <img src={props.image} alt={props.header_text} />
                </div>
                <div className="text-container">
                    <h2 style={{ color: theme.color.primary }}>
                        {props.header_text}
                    </h2>
                    <p style={{ color: 'grey' }}>{props.main_text}</p>
                    <a href={props.link_address}>{props.link_text}</a>
                </div>
            </div>
        </div>
    </section>
)
