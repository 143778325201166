import React from 'react'
import './style.css'
import { Theme } from '../../../types'

interface Props {
    props: {
        text: string
        text_color: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="text-container-component container">
        <div className="row">
            <div className="col-lg-12">
                <h2 style={{ color: theme.color.primary }}>{props.text}</h2>
            </div>
        </div>
    </section>
)
