import React, { useState, useEffect, useRef } from 'react'
import { House } from '../../../types/ContentBuilder'
import { Theme } from '../../../types'
import './style.css'
import Tabs from './Tabs'
import HouseCard from './HouseCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface Props {
    props: {
        house_type?: {
            house_type: string
            houses: House[]
        }[]
        data_source_api_endpoint: string
        data_source: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => {
    const [tabs, setTabs] = useState<string[]>([])
    const [houses, setHouses] = useState<House[][]>()
    const [activeTab, setActiveTab] = useState<number>(0)
    const [opacity, setOpacity] = useState<number>(1)
    const [fixedNavBar, setFixedNavBar] = useState<boolean>(false)
    const ref = useRef(null)

    const mapCMSDataToComponent = () => {
        if (props.data_source === 'cms' && props.house_type) {
            const _tabs = props.house_type.map(house => house.house_type)
            const _houses = props.house_type.map(house_type =>
                house_type.houses.map(house => house)
            )
            _tabs.unshift('All')
            setTabs(_tabs)
            setHouses(_houses)
        }
    }
    // const mapExternalDataToComponent = () => {
    //     if (
    //         props.data_source === 'external' &&
    //         props.data_source_api_endpoint
    //     ) {
    //         getHouseAndLandPackages(props.data_source_api_endpoint).then(
    //             packages => {
    //                 setTabs(['All', 'Single Storey', 'Double Storey'])
    //                 const singleStorey = packages.filter(
    //                     house => house.storeys === 'Single'
    //                 )
    //                 const doubleStorey = packages.filter(
    //                     house => house.storeys === 'Double'
    //                 )
    //                 const allHouses = []
    //                 allHouses.push(singleStorey)
    //                 allHouses.push(doubleStorey)
    //                 setHouses(allHouses)
    //             }
    //         )
    //     }
    // }
    const changeActiveHouseType = (index: number) => {
        setOpacity(0)
        setTimeout(() => {
            setActiveTab(index)
            setOpacity(1)
        }, 600)
    }

    const handleScroll = () =>
        setFixedNavBar(ref.current.getBoundingClientRect().top <= 50)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        mapCMSDataToComponent()
        // mapExternalDataToComponent()
    }, [])

    return (
        <section className="house_listing_with_cards" ref={ref}>
            {houses ? (
                <div className="content-container">
                    <div
                        className="tabs-container"
                        style={{ position: fixedNavBar ? 'fixed' : 'static' }}
                    >
                        <ul>
                            {tabs.map((tab, index) => (
                                <Tabs
                                    key={index}
                                    props={{ label: tab, activeTab, index }}
                                    onClick={() => changeActiveHouseType(index)}
                                    theme={theme}
                                />
                            ))}
                        </ul>
                    </div>
                    <div className="main-content-container">
                        <div
                            className="house-list-container container-fluid"
                            style={{ opacity }}
                        >
                            <ul className="row">
                                {activeTab == 0
                                    ? houses.map(house =>
                                          house.map((_house, index) => (
                                              <HouseCard
                                                  key={index}
                                                  props={_house}
                                                  theme={theme}
                                              />
                                          ))
                                      )
                                    : houses[
                                          activeTab - 1
                                      ].map((house, index) => (
                                          <HouseCard
                                              key={index}
                                              props={house}
                                              theme={theme}
                                          />
                                      ))}
                            </ul>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="loading-container">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        color={theme.color.secondary}
                        spin={true}
                        className="loading"
                    />
                </div>
            )}
        </section>
    )
}
