import React, { useState } from 'react'
import './style.css'
import { House } from '../../../../types/ContentBuilder'
import { Theme } from '../../../../types'
import RoomList from './RoomList'
import About from './About'

interface Props {
    props: House
    theme: Theme
}

export default ({ props, theme }: Props) => {
    const [readMore, setReadMore] = useState<boolean>(false)
    return (
        <li className="col-xl-3 col-md-6">
            <div className="house-card" onClick={() => setReadMore(!readMore)}>
                <div
                    className="image"
                    style={{ backgroundImage: `url(${props.image})` }}
                />
                <div className="text-container">
                    <div className="about-container">
                        <h4 style={{ color: theme.color.primary }}>
                            {props.address}
                        </h4>
                        <RoomList props={props.rooms} theme={theme} />
                        <div className="location-container">
                            <span
                                className="location"
                                style={{ color: theme.color.secondary }}
                            >
                                {props.location.location},
                            </span>
                            <span className="suburb">
                                {' '}
                                {props.location.suburb}
                            </span>
                        </div>
                        <About
                            props={props.about}
                            theme={theme}
                            state={{ state: readMore, setState: setReadMore }}
                        />
                    </div>
                    <div>
                        {/* <a
                            href={props.flyer.address}
                            className="flyer"
                            target="_blank"
                        >
                            <div
                                className="image"
                                style={{
                                    backgroundImage: `url(${downloadImage})`,
                                }}
                            />
                            <span>{props.flyer.text}</span>
                        </a> */}
                        {props.price ? <h5>${props.price}</h5> : <></>}
                    </div>
                </div>
                <a
                    href={props.link.address}
                    style={{ backgroundColor: theme.color.secondary }}
                    target="_blank"
                >
                    {props.link.text}
                </a>
            </div>
        </li>
    )
}
