import ContentBuilder from '../components/ContentBuilder'
import { SectionComponent } from '../types'

/**
 * Maps a section string to a React component, passing
 * in the section's field groups as props
 * @param acf_layout Array of ACF Layouts
 * @returns An array of mapped components and props
 *          and false if no match is found
 */
export default (acf_layout: [] | false) => {
    if (!acf_layout) return false
    return acf_layout.map((layout: any) => {
        switch (layout.acf_fc_layout) {
            case 'header_image_with_text':
                return {
                    component: ContentBuilder.HeaderImageWithText,
                    props: layout,
                } as SectionComponent
            case 'double_image_right_aligned_text':
                return {
                    component: ContentBuilder.DoubleImageRightAlignedText,
                    props: layout,
                } as SectionComponent
            case 'image_right_text_left':
                return {
                    component: ContentBuilder.ImageRightTextLeft,
                    props: layout,
                } as SectionComponent
            case 'image_with_text_left':
                return {
                    component: ContentBuilder.ImageWithTextLeft,
                    props: layout,
                } as SectionComponent
            case 'gallery_grid':
                return {
                    component: ContentBuilder.GalleryGrid,
                    props: layout,
                } as SectionComponent
            case 'double_image_left_aligned_text':
                return {
                    component: ContentBuilder.DoubleImageLeftAlignedText,
                    props: layout,
                } as SectionComponent
            case 'image_left_text_right':
                return {
                    component: ContentBuilder.ImageLeftTextRight,
                    props: layout,
                } as SectionComponent
            case 'contact_form':
                return {
                    component: ContentBuilder.ContactForm,
                    props: layout,
                } as SectionComponent
            case 'floorplan_list':
                return {
                    component: ContentBuilder.FloorplanList,
                    props: layout,
                } as SectionComponent
            case 'text_container':
                return {
                    component: ContentBuilder.TextContainer,
                    props: layout,
                } as SectionComponent
            case 'call_to_action':
                return {
                    component: ContentBuilder.CallToAction,
                    props: layout,
                } as SectionComponent
            case 'text_container_two_fields':
                return {
                    component: ContentBuilder.TextContainerTwoFields,
                    props: layout,
                } as SectionComponent
            case 'checkbox':
                return {
                    component: ContentBuilder.Checkbox,
                    props: layout,
                } as SectionComponent

            case 'image_left_cta_right':
                return {
                    component: ContentBuilder.ImageLeftCtaRight,
                    props: layout,
                } as SectionComponent
            case 'image_right_cta_left':
                return {
                    component: ContentBuilder.ImageRightCtaLeft,
                    props: layout,
                } as SectionComponent
            case 'header_center_text':
                return {
                    component: ContentBuilder.HeaderCenterText,
                    props: layout,
                } as SectionComponent
            case 'full_size_header_with_text_and_image':
                return {
                    component: ContentBuilder.FullSizeHeaderWithTextAndImage,
                    props: layout,
                } as SectionComponent
            case 'categories_list_boxes':
                return {
                    component: ContentBuilder.CategoriesListBoxes,
                    props: layout,
                } as SectionComponent
            case 'amenities_map':
                return {
                    component: ContentBuilder.AmenitiesMap,
                    props: layout,
                } as SectionComponent
            case 'categories_list_four_boxes':
                return {
                    component: ContentBuilder.CategoriesListFourBoxes,
                    props: layout,
                } as SectionComponent
            case 'cicrle_image_with_text':
                return {
                    component: ContentBuilder.CircleImageWithText,
                    props: layout,
                } as SectionComponent
            case 'faq':
                return {
                    component: ContentBuilder.FAQ,
                    props: layout,
                } as SectionComponent
            case 'floorplan_shop':
                return {
                    component: ContentBuilder.FloorplanShop,
                    props: layout,
                } as SectionComponent
            case 'free_text_container':
                return {
                    component: ContentBuilder.FreeTextContainer,
                    props: layout,
                } as SectionComponent
            case 'full_size_call_to_action':
                return {
                    component: ContentBuilder.FullSizeCallToAction,
                    props: layout,
                } as SectionComponent
            case 'header_image_text_center':
                return {
                    component: ContentBuilder.HeaderImageTextCenter,
                    props: layout,
                } as SectionComponent
            case 'header_with_image_and_link':
                return {
                    component: ContentBuilder.HeaderImageWithLink,
                    props: layout,
                } as SectionComponent
            case 'horizontal_image_list_view':
                return {
                    component: ContentBuilder.HorizontalImageListView,
                    props: layout,
                } as SectionComponent
            case 'house_listing_with_cards':
                return {
                    component: ContentBuilder.HouseListingWithCards,
                    props: layout,
                } as SectionComponent
            case 'house_shop_details':
                return {
                    component: ContentBuilder.HouseShopDetails,
                    props: layout,
                } as SectionComponent
            case 'image_left_text_right_modal':
                return {
                    component: ContentBuilder.ImageRightTextLeftWithModal,
                    props: layout,
                } as SectionComponent
            case 'inclusions_list':
                return {
                    component: ContentBuilder.InclusionsList,
                    props: layout,
                } as SectionComponent
            case 'profile_summary':
                return {
                    component: ContentBuilder.ProfileSummary,
                    props: layout,
                } as SectionComponent
            case 'text_container_with_header':
                return {
                    component: ContentBuilder.TextContainerWithHeader,
                    props: layout,
                } as SectionComponent
            case 'two_image_containers_with_link_and_image':
                return {
                    component:
                        ContentBuilder.TwoImageContainersWithLinkAndImage,
                    props: layout,
                } as SectionComponent
            case 'two_image_containers_with_text':
                return {
                    component: ContentBuilder.TwoImageContainersWithText,
                    props: layout,
                } as SectionComponent
            case 'two_image_with_top_header_text':
                return {
                    component: ContentBuilder.TwoImageWithTopHeaderText,
                    props: layout,
                } as SectionComponent
            case 'image':
                return {
                    component: ContentBuilder.Image,
                    props: layout,
                } as SectionComponent

            default:
                return false
        }
    }) as SectionComponent[]
}
