import React, { useState } from 'react'
import './style.css'
import Button from '../../Common/Button'
import { Theme } from '../../../types'
import { Link } from 'react-scroll'

interface Props {
    props: {
        header: {
            text: string
            color: string
        }
        sub_header: {
            text: string
            color: string
        }
        button: {
            text: string
            address: string
            sign_up: boolean
        }
        image: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => {
    const [onButtonHover, setOnButtonHover] = useState<boolean>(false)
    return (
        <section
            className="full_size_header_with_text_and_image image"
            style={{ backgroundImage: `url(${props.image})` }}
        >
            <div className="text-container">
                <h4 style={{ color: props.header.color }}>
                    {props.header.text}
                </h4>
                <h6 style={{ color: props.sub_header.color }}>
                    {props.sub_header.text}
                </h6>
                {props.button.sign_up ? (
                    <Link
                        to={'subscribe-form'}
                        offset={-250}
                        smooth={true}
                        duration={1000}
                        className="sign-up-button"
                        onMouseEnter={() => setOnButtonHover(true)}
                        onMouseLeave={() => setOnButtonHover(false)}
                        style={{
                            backgroundColor: onButtonHover
                                ? theme.color.primary
                                : theme.color.secondary,
                        }}
                    >
                        {props.button.text}
                    </Link>
                ) : (
                    <Button
                        label={props.button.text}
                        onClick={() => {}}
                        onHoverStyle={{
                            backgroundColor: theme.color.primary,
                        }}
                        style={{
                            backgroundColor: theme.color.secondary,
                        }}
                    />
                )}
            </div>
        </section>
    )
}
