import React from 'react'
import { Theme } from '../../../types/'

interface Props {
    props: {
        category: {
            image: string
            header: {
                text: string
                address: string
            }
        }
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <div className="col-xl-3 col-lg-6 col-md-6 category-item">
        <div
            className="image"
            style={{ backgroundImage: `url(${props.category.image})` }}
        />
        <a
            style={{ color: theme.color.secondary }}
            href={props.category.header.address}
        >
            {props.category.header.text}
        </a>
    </div>
)
