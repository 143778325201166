import React, { useState } from 'react'
import { Theme } from '../../../../types'
import Button from '../../../Common/Button'

interface Props {
    props: any
    theme: Theme
}

export default ({ props, theme }: Props) => {
    const [email, setEmail] = useState<string>('')
    return (
        <div className="call-to-action row">
            <div className="col-md-8">
                <input
                    placeholder="Enter your email..."
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                />
            </div>
            <div className="col-md-4">
                <Button
                    label={props.button.text}
                    onClick={() => {}}
                    style={{ backgroundColor: theme.color.secondary }}
                    onHoverStyle={{ backgroundColor: theme.color.primary }}
                />
            </div>
        </div>
    )
}
