import React from 'react'
import { Theme } from '../../../types/'
import './style.css'

interface Props {
    props: {
        header_text: string
        background_color?: string
        inclusions_list: { inclusion: string }[]
        download_link_address?: string
        download_link_text?: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section
        className="inclusions_list"
        style={{ backgroundColor: props.background_color }}
    >
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h3 style={{ color: theme.color.primary }}>
                        {props.header_text}
                    </h3>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 text-padding">
                    {props.inclusions_list.map((inclusion, index) => {
                        if (!(index % 2)) return null
                        return <p key={index}>{inclusion.inclusion}</p>
                    })}
                </div>
                <div className="col-lg-6 text-padding">
                    {props.inclusions_list.map((inclusion, index) => {
                        if (index % 2) return null
                        return <p key={index}>{inclusion.inclusion}</p>
                    })}
                </div>
            </div>
            {props.download_link_address ? (
                <div className="row">
                    <div className="col-lg-12">
                        <a href={props.download_link_address}>
                            {props.download_link_text}
                        </a>
                    </div>
                </div>
            ) : null}
        </div>
    </section>
)
