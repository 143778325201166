import React from 'react'
import { Rooms } from '../../../../types/ContentBuilder'
import { RoomType } from '../../../../types/HomeDesigns'
import { Theme } from '../../../../types'
import Room from '../../../Common/Room'
import './style.css'

interface Props {
    props: Rooms
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <div className="rooms-container">
        <ul>
            {props.bedroom ? (
                <Room
                    room={{
                        count: parseInt(props.bedroom),
                        type: RoomType.Bedroom,
                    }}
                />
            ) : null}
            {props.living_room ? (
                <Room
                    room={{
                        count: parseInt(props.living_room),
                        type: RoomType.LivingRoom,
                    }}
                />
            ) : null}
            {props.bathroom ? (
                <Room
                    room={{
                        count: parseInt(props.bathroom),
                        type: RoomType.Bathroom,
                    }}
                />
            ) : null}
            {props.garage ? (
                <Room
                    room={{
                        count: parseInt(props.garage),
                        type: RoomType.Garage,
                    }}
                />
            ) : null}
        </ul>
    </div>
)
