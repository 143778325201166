import axios from 'axios'
import { SiteConfig } from '../../../../types/'

export default (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    config: SiteConfig
): Promise<any> => {
    const formData = JSON.stringify({
        input_1: firstName,
        input_2: lastName,
        input_5: email,
        input_7: phone,
    })
    const formEndpoint =
        config.API_ENDPOINT +
        'gf/v2/forms/1/submissions?consumer_key=' +
        config.GRAVITY_CONSUMER_KEY +
        '&consumer_secret=' +
        config.GRAVITY_CONSUMER_SECRET
    return axios({
        url: formEndpoint,
        method: 'POST',
        data: formData,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        auth: {
            username: config.GRAVITY_CONSUMER_KEY,
            password: config.GRAVITY_CONSUMER_SECRET,
        },
    })
}
