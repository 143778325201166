import React, { useState } from 'react'
import FloorplanItem from './FloorplanItem'
import { FloorPlan } from '../../../types/ContentBuilder'
import { Theme } from '../../../types/'
import './style.css'
import Arrow from './Arrow'

const arrows = {
    left: require('../../../images/left_arrow.png'),
    right: require('../../../images/right_arrow.png'),
}

interface Props {
    props: {
        header_text: string
        header_background_image: string
        floorplans: FloorPlan[]
    }
    theme: Theme
}

export default ({ props, theme }: Props) => {
    const floorplanCount = props.floorplans.length > 2
    const [index, setIndex] = useState<number>(0)
    const [showFloorplans, setShowFloorplans] = useState<string>(
        'show-animation'
    )
    const updateindex = (index: number) => {
        setShowFloorplans('hide-animation')
        setTimeout(() => {
            setIndex(index)
            setShowFloorplans('show-animation')
        }, 305)
    }
    const getIndex = () => {
        if (index === props.floorplans.length - 1) {
            return 0
        } else {
            return index + 1
        }
    }
    return (
        <section className="floorplan_list container-fluid">
            <div className="row">
                <div
                    className="col-lg-12 background-image"
                    style={{
                        backgroundImage: `url(${props.header_background_image})`,
                    }}
                >
                    <h3 style={{ color: theme.color.primary }}>
                        {props.header_text}
                    </h3>
                </div>
            </div>
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div
                            className={`col-lg-6 floorplan-container left ${showFloorplans}`}
                        >
                            <Arrow
                                onClick={() => {
                                    if (index > 0) updateindex(index - 1)
                                }}
                                arrowDirection={'left'}
                                arrowImage={arrows.left}
                                show={floorplanCount}
                            />
                            <FloorplanItem
                                floorplan={props.floorplans[index]}
                                theme={theme}
                            />
                            <Arrow
                                onClick={() => {
                                    if (index < props.floorplans.length - 1)
                                        updateindex(index + 1)
                                    else if (
                                        index ===
                                        props.floorplans.length - 1
                                    )
                                        updateindex(0)
                                }}
                                arrowDirection={'right'}
                                arrowImage={arrows.right}
                                show={floorplanCount}
                            />
                        </div>
                        <div
                            className={`col-lg-6 floorplan-container right ${showFloorplans}`}
                        >
                            <Arrow
                                onClick={() => {
                                    if (index < props.floorplans.length - 1)
                                        updateindex(index + 1)
                                    else if (
                                        index ===
                                        props.floorplans.length - 1
                                    )
                                        updateindex(0)
                                }}
                                arrowDirection={'right'}
                                arrowImage={arrows.right}
                                show={floorplanCount}
                            />
                            <FloorplanItem
                                floorplan={props.floorplans[getIndex()]}
                                theme={theme}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
